<section class="barra" *ngIf="!cargandoPedido">

    <div class="menu">
        <div class="opcion" *ngFor="let opcion of menu.opciones; let i = index" 
        [matTooltip]="opcion.tooltip" matTooltipPosition="right" 
        [ngClass]="i == opcionSeleccionada && 'seleccionado'" 
        (click)="cambiarOpcion(opcion?.opcion || i)">
            <mat-icon 
                [svgIcon] = "opcion.icono" 
                [matBadge]="opcion.badges && opcion.badges[i] && opcion.badges[i] > 0 ? opcion.badges[i] : null"
                matBadgeOverlap="false" 
                matBadgeSize="small" 
                matBadgeColor="warn"
                >
            </mat-icon>
        </div>
    </div>
    
    <div class="submenu" *ngIf="menu.opciones[opcionSeleccionada]?.submenu && menu.opciones[opcionSeleccionada]?.submenu?.length > 0">
        <div class="submenu_titulo">{{menu.opciones[opcionSeleccionada].tooltip}}</div>
        <div class="submenu_opcion" 
        *ngFor="let opcion of menu.opciones[opcionSeleccionada].submenu; let i = index;" 
        [ngClass]="i == subOpcionSeleccionada && 'submenu_opcion_seleccionado'" 
        (click)="cambiarSubOpcion(i)">
            <div *ngIf="opcion.badge > 0" class="badge">{{opcion.badge}}</div>
            <mat-icon *ngIf="opcion.icono && opcion.icono != ''" [svgIcon]="opcion.icono"></mat-icon>
            <div class="titulo-opc">{{opcion.titulo}}</div>
        </div>        
    </div>

</section>