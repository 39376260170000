import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getDecodedToken } from 'src/app/core/helpers/tokenReader';
import { menus } from 'src/app/layout/Navegacion/leftbar/menus-templates/menus';
import { LeftBarService } from './services/leftbar.service';

import tiposDeCuentas from 'src/app/core/enums/AccountTypes';
import { Subscription } from 'rxjs';
import { CajaService } from 'src/app/core/services/caja.service';
import { Global } from 'src/app/global/Global';
import { CargarSaldoService } from 'src/app/modules/cliente/caja/components/carga-saldo/carga-saldo.service';
import { CajaClienteService } from 'src/app/modules/cliente/caja/caja.service';
import { TipoCuenta } from './menus-templates/menus.inteface';
import { Utils } from 'src/app/global/Utils';

@Component({
standalone: false,
  selector: 'leftbar',
  templateUrl: './leftbar.component.html',
  styleUrls: ['./leftbar.component.scss']
})

export class LeftbarComponent implements OnInit, OnDestroy {

  public tipoCuenta: string  = ""; // Sirve para saber que opciones traer al leftbar 
  public menu                     // Guardará las opciones del leftbar
  public opcionSeleccionada = 0;   // Sirve para saber que opción está seleccionada
  public subOpcionSeleccionada = 0;
  public cargandoPedido = false

  private suscripciones = new Subscription();

  constructor(
    private router: Router,                 // Sirve para saber que opción está seleccionada al apretar F5
    private leftBarService: LeftBarService, // Sirve para setear la opción seleccionada en el servicio del leftbar
    private _cajaService: CajaService,
    private _cargarSaldoService: CargarSaldoService,
    private _cajaClienteService: CajaClienteService
  ) {}

  ngOnInit(): void {
    this.menu = menus[Utils.obtenerTipoCuenta()];
    const decodedToken = getDecodedToken(); // Traemos el token
    this.tipoCuenta = tiposDeCuentas[(decodedToken.user.tipo==999)?this.tipoCuenta.length-1:decodedToken.user.tipo-1];           
    const indInicial = 0;
    this.router?.getCurrentNavigation()?.extras?.state?.['indice']; 
    this.opcionSeleccionada = (indInicial || indInicial == 0) ? indInicial : 0;   

    this.agregarSuscripciones()

    let navegation:any = localStorage.getItem('lastNavigation')
    navegation = JSON.parse(navegation)

    this.leftBarService.cambiarOpcion(navegation?.optionIndex)
    this.leftBarService.cambiarSubOpcion(navegation?.subOptionIndex)

    this._cajaService.getCuentaCorriente().then((res:any) => {
      if(res.status == 200){
        if(res.datosCuentaCorriente?.cuentaCorriente == 1 && Number(res.datosCuentaCorriente.balance) < 0){
          const fecha = new Date()
          const fechaBalance = new Date(res.datosCuentaCorriente.fechaBalance)
          const dia = fecha.getDate()
          if(dia >= 10 && (fecha.getMonth() != fechaBalance.getMonth())){
            this.menu.opciones.splice(2,1)
          }
        }
      }
    })
    // (Es práctica recomendada agregar las suscripciones en el On Init y no en el constructor)
  }

  agregarSuscripciones(){
    this.suscripciones.add(this.leftBarService.opcionSeleccionada.subscribe((opcion:number)=>{
      this.opcionSeleccionada = opcion;
    }))

    this.suscripciones.add(this.leftBarService.subOpcionSeleccionada.subscribe((subOpcion:number) => {
      this.subOpcionSeleccionada = subOpcion
    }))

    this.suscripciones.add(this.leftBarService.cargandoPedido.subscribe((cargando:boolean)=>{
      this.cargandoPedido = cargando;
    }))

    this.suscripciones.add(this.leftBarService.badges.subscribe(badges => {
      this.actualizarBadges(badges);
    }));

    this.suscripciones.add(this.leftBarService.sancioneCuentaCorriente.subscribe((sancion) => {

      Global.sancionCuentaCorriente = sancion

      if(sancion == 0){
        this.menu = menus[Utils.obtenerTipoCuenta()];
      }

      if(sancion == 1){
      }

      if(sancion == 2){
        this.leftBarService.cambiarOpcion(2)
        this.leftBarService.cambiarSubOpcion(0)
        this.menu.opciones = this.menu.opciones.filter((opcion:any) => opcion.tooltip == 'Caja')
      }
    }))
  }

  cambiarOpcion(i:number) {  
    this.opcionSeleccionada = i; // Seteamos la opción seleccionada por motivos de visualización (css)
    this.leftBarService.cambiarOpcion(i); // Seteamos la opción seleccionada en el servicio del leftbar 
    this._cargarSaldoService.deshabilitarPagarConCargarSaldo()

    this._cajaClienteService.modificarPagoCuentaCorriente(false)
    localStorage.removeItem('pagarMetodo')
    this.redireccionar(this.menu.opciones[this.opcionSeleccionada]?.submenu[0]?.ruta || this.menu?.opciones[this.opcionSeleccionada]?.ruta || this.menu.ruta)
  }

  redireccionar(ruta:string){
    this.router.navigateByUrl(ruta)
  }

  cambiarSubOpcion(i:number) {
    this.subOpcionSeleccionada = i; // Seteamos la opción seleccionada por motivos de visualización (css)
    this.leftBarService.cambiarSubOpcion(i); // Seteamos la opción seleccionada en el servicio del leftbar 
    this._cargarSaldoService.deshabilitarPagarConCargarSaldo()

    this._cajaClienteService.modificarPagoCuentaCorriente(false)
    localStorage.removeItem('pagarMetodo')
    this.redireccionar(this.menu.opciones[this.opcionSeleccionada]?.submenu[this.subOpcionSeleccionada]?.ruta || this.menu.ruta)
  }

  actualizarBadges(badges: number[]) {
    if (this.tipoCuenta === 'cliente' && this.menu?.opciones) {
      const opcionPedidos = this.menu.opciones.find(opcion => opcion.tooltip === 'Pedidos');
      if (opcionPedidos && opcionPedidos.submenu) {
        opcionPedidos.submenu.forEach((submenu, index) => {
          submenu.badge = badges[index] || 0;
        });
      }
    }
  }

  ngOnDestroy(): void {
    // Cuando el componente se destruye hay que eliminar las suscripciones para evitar fugas de memoria
    this.suscripciones.unsubscribe();
  }

}