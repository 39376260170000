import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LeftbarComponent } from './leftbar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
    declarations: [
        LeftbarComponent
       
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        MatBadgeModule
    ],
    exports:[
        LeftbarComponent
    ]
})
export class LeftbarModule
{
}
